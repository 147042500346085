// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-en-ca-contact-jsx": () => import("./../../../src/pages/en-ca/contact.jsx" /* webpackChunkName: "component---src-pages-en-ca-contact-jsx" */),
  "component---src-pages-en-ca-downloads-jsx": () => import("./../../../src/pages/en-ca/downloads.jsx" /* webpackChunkName: "component---src-pages-en-ca-downloads-jsx" */),
  "component---src-pages-fr-ca-contact-jsx": () => import("./../../../src/pages/fr-ca/contact.jsx" /* webpackChunkName: "component---src-pages-fr-ca-contact-jsx" */),
  "component---src-pages-fr-ca-telechargements-jsx": () => import("./../../../src/pages/fr-ca/telechargements.jsx" /* webpackChunkName: "component---src-pages-fr-ca-telechargements-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-archive-jsx": () => import("./../../../src/templates/archive.jsx" /* webpackChunkName: "component---src-templates-archive-jsx" */),
  "component---src-templates-blog-jsx": () => import("./../../../src/templates/blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-category-jsx": () => import("./../../../src/templates/category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-templates-home-jsx": () => import("./../../../src/templates/home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-job-jsx": () => import("./../../../src/templates/job.jsx" /* webpackChunkName: "component---src-templates-job-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-subpage-jsx": () => import("./../../../src/templates/subpage.jsx" /* webpackChunkName: "component---src-templates-subpage-jsx" */)
}

