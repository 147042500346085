import React from 'react'
import PropTypes from 'prop-types'
import {
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles'

const DarkMode = ({ children }) => (
  <ThemeProvider
    theme={(theme) =>
      createMuiTheme({
        ...theme,
        palette: {
          type: 'dark',
          primary: {
            main: '#BA1624',
            contrastText: '#FFF',
          },
          secondary: {
            main: '#303030',
          },
        },
      })
    }
  >
    {children}
  </ThemeProvider>
)

DarkMode.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.object,
    PropTypes.func,
  ]).isRequired,
}

export default DarkMode
