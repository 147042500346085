import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { makeStyles } from '@material-ui/core'
import * as Accessa from 'accessa'
import RichText from './RichText'
import DarkMode from './DarkMode'
import {
  areAllConditionsTruthy,
  includesSome,
  invokeOnString,
} from '../utils/arrayHelpers'
import { getAsArray } from '../utils/helpers'

export const CONTAINS = 'includes'
export const STARTS_WITH = 'startsWith'

const localeCompare = (a, b) => a.name.localeCompare(b.name)

export const MaterialsFilterRunner = (
  inputs = [],
  options = {}
) =>
  inputs.reduce((acc, next) => {
    if (!next) return acc

    const doesMaterialIncludesRequestedCategoryName = includesSome(
      getAsArray(options, 'categories'),
      next.category
    )

    const doesMaterialNameMatchesSearchPhrase = invokeOnString(
      get(options, 'phrase'),
      get(next, 'name'),
      get(options, 'phraseOp')
    )

    return areAllConditionsTruthy([
      doesMaterialIncludesRequestedCategoryName,
      doesMaterialNameMatchesSearchPhrase,
    ])
      ? acc.concat(next)
      : acc
  }, [])

const useStyles = makeStyles(() => ({
  contrastColor: {
    color: 'rgba(255, 255, 255, 0.7)',

    '& svg': {
      color: '#fff',
    },
  },
  ul: {
    '& ul': {
      display: 'grid',
      gridTemplateColumns:
        'repeat(auto-fill, minmax(250px, 1fr))',

      '& li': {
        padding: 0,
      },
    },
  },
}))

const Materials = ({ materials, ...props }) => {
  const { ul } = useStyles()

  return (
    <DarkMode>
      <RichText {...props} />
      <div className={ul} style={{ marginTop: '2rem' }}>
        <Accessa.Lists.Archer
          lists={materials
            .sort(localeCompare)
            .map((item) => ({
              ...item,
              listItemText: item.name,
            }))}
        />
      </div>
    </DarkMode>
  )
}

Materials.propTypes = {
  materials: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      // eslint-disable-next-line react/forbid-prop-types
      categories: PropTypes.any,
    })
  ).isRequired,
}

export default Materials
