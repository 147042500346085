/* eslint-disable react/jsx-filename-extension */

import React from 'react'
import Theme from './src/components/Theme'
import i18n from './src/utils/i18n'

export const wrapPageElement = ({ element, ...rest }) => {
  const path = rest?.props?.path
  if (path)
    i18n.changeLanguage(
      path.startsWith('/en') ? 'en' : 'fr'
    )

  return <Theme>{element}</Theme>
}
