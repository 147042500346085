import React from 'react'
import {
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#BA1624',
      dark: '#87101A',
      light: '#ED1C2C',
      contrastText: '#FFF',
    },
    secondary: {
      main: 'rgb(45 45 45)',
      dark: '#212121',
      light: '#474747',
      contrastText: '#FFF',
    },
    text: {
      secondary: {
        color: 'rgb(0 0 0 / 68%)',
      },
    },
  },
  typography: {
    h1: {
      fontSize:
        'calc( 36px + (36 - 25.629) * ((100vw - 300px) / (1600 - 300)) )',
      margin: '4rem 0 2rem',
    },
    h2: {
      fontSize: '1.885rem',
      marginTop: '2rem',
      fontWeight: 700,
    },
    h3: {
      fontSize: '1.585rem',
      margin: '0 0 1rem',
      fontWeight: 700,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
    MuiCssBaseline: {
      '@global': {
        '.skipLink': {
          boxSizing: 'border-box',
          position: 'absolute',
          width: 0,
          overflow: 'hidden',
          top: '1rem',
          left: '1rem',
          background: 'white',
          padding: '1rem',
          height: 0,
        },
        '.skipLink:focus': {
          height: 'auto',
          width: 'auto',
          zIndex: 10000,
        },
        '.offcanvas-links li:last-of-type': {
          border: 'none',
        },
        '.offcanvas-links a': {
          color: 'inherit',
        },
      },
    },
  },
})

// eslint-disable-next-line
export default ({ children }) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </ThemeProvider>
)
