import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import {
  CONTAINS,
  STARTS_WITH,
} from '../components/Materials'

const resources = {
  en: {
    titles: {
      applications: 'Applications',
      industries: 'Industries',
      blogs: 'Blogs',
      relatedApplications:
        'Related applications and resources',
      relatedServices: 'Related services',
      commonMaterials: 'Common materials',
      commonProducts: 'Common products',
      contact: 'Contact us',
      hoursOfOperation: 'Hours of operation',
      waysToGetInTouch: 'How to reach us',
      downloads: 'Downloads',
      relatedResources: 'Related resources',
      submitYourApplication: 'Submit your application',
      certificates:
        'Our certifications, awards & associations',
    },
    descriptions: {
      preferenceDetected:
        "A language preference has been detected and you'll be redirected in 5 seconds.",
      inquiries:
        'For general inquiries and career opportunities, please reach out by:',
      noMatch:
        'No results. Please try searching or filtering differently.',
      filter:
        'Narrow the results by searching for a key term or within a category',
      showingResults:
        'Showing results {{num}} of {{total}}',
      resumeSubmission:
        'Thank you for applying for the role!',
      resumeSubmissionFail:
        'Sorry something went wrong. Please apply later',
    },
    labels: {
      'en-ca': 'Home',
      'fr-ca': 'Home',
      cancel: 'Cancel',
      trans: 'Traduire en {{code}}',
      readMore: 'Read more',
      contact: 'Contact',
      fax: 'Fax',
      northAmerica: 'North American Toll-Free',
      local: 'Local Phone Number',
      email: 'Email',
      name: 'Name',
      resume: 'Resume',
      coverLetter: 'Cover letter',
      downloads: 'Downloads',
      downloadsPath: '/en-ca/downloads',
      blogPath: '/en-ca/blog',
      selectCategory: 'Select a category',
      all: 'All',
      search: 'Search',
      categories: 'Categories',
      applications: 'Applications',
      industries: 'Industries',
      blog: 'Blog',
      related: 'Related articles on CRG',
      learnMore: 'Learn more',
      [CONTAINS]: 'Includes',
      [STARTS_WITH]: 'Starts with',
      privacy: 'Privacy',
      careers: 'Careers',
      resources: 'Resources',
      apply: 'Apply',
      contactInformation: 'Contact Information',
      location: 'Location',
      keyword: 'Keyword',
      openings: 'Current Openings',
      next: 'Next',
      previous: 'Previous',
    },
  },
  fr: {
    titles: {
      applications: 'Applications',
      industries: 'Industries',
      blogs: 'Blogues',
      blog: 'Blogue',
      relatedApplications:
        'Applications et ressources connexes',
      relatedServices: 'Services connexes',
      commonMaterials: 'Matériaux communs',
      commonProducts: 'Produits communs',
      contact: 'Contactez-nous',
      hoursOfOperation: 'Heures d’ouverture',
      waysToGetInTouch: 'Comment nous rejoindre',
      downloads: 'Téléchargements',
      relatedResources: 'Ressources connexes',
      submitYourApplication: 'Soumettez votre candidature',
      certificates:
        'Nos certifications, récompenses & associations',
    },
    descriptions: {
      preferenceDetected:
        'Une préférence de langue a été détectée et vous serez redirigé dans 5 secondes.',
      inquiries:
        'Pour les demandes générales et les opportunités de carrière, veuillez contacter:',
      noMatch:
        'Aucun résultat. Veuillez essayer de rechercher ou de filtrer différemment.',
      filter:
        "Affinez les résultats en recherchant un terme clé ou au sein d'une catégorie",
      showingResults:
        'Affichage des résultats {{num}} de {{total}}',
      resumeSubmission:
        "Merci d'avoir postulé pour le poste!",
      resumeSubmissionFail:
        "Désolé, quelque chose s'est mal passé. Veuillez postuler plus tard",
    },
    labels: {
      'en-ca': 'Accueil',
      'fr-ca': 'Accueil',
      cancel: 'Annuler',
      trans: 'Translate into {{code}}',
      readMore: 'En savoir plus',
      contact: 'Contact',
      fax: 'Fax',
      northAmerica: 'Numéro nord-américain sans frais',
      local: 'Numéro de téléphone local',
      email: 'Courriel',
      name: 'Npm',
      resume: 'CV',
      coverLetter: 'Lettre de motivation',
      downloads: 'Téléchargements',
      downloadsPath: '/fr-ca/telechargements',
      blogPath: '/fr-ca/blogue',
      selectCategory: 'Sélectionner une catégorie',
      all: 'Tout',
      search: 'Chercher',
      categories: 'Catégories',
      applications: 'Applications',
      industries: 'Industries',
      blog: 'Blogue',
      related: 'Articles Liés',
      learnMore: 'Apprendre encore plus',
      [CONTAINS]: 'Comprend',
      [STARTS_WITH]: 'Commence avec',
      privacy: 'Intimité',
      careers: 'Carrières',
      resources: 'Ressources',
      apply: 'Appliquer',
      contactInformation: 'Informations de contact',
      location: 'Adresse',
      keyword: 'Mot-clé',
      carrires: 'Carrières',
      openings: 'Postes disponibles',
      next: 'Suivante',
      previous: 'Précédent',
    },
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
